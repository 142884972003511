import React from 'react'
import SectionTitle from '../Ui/SectionTitle'
import { getHoursWith2023Format } from '../../utils/formattedDate'
import getTicketURL from '../../utils/TicketURL'

const Appearances = ({ data, numberOfAppearances, isFree }) => {
  const { lpdv_id: lpdvID, stage } = data.event.document.data
  const { title } = stage.document.data.place.document.data
  const appearances = []

  data.event.document.event_appearances.data.allPrismicAppearances.edges.forEach(
    ({ node }) => {
      appearances.push(node.data)
    }
  )

  const getDateTime = (i = 0) => {
    const appearance = appearances.sort((a, b) => new Date(a.time) - new Date(b.time))[i]
    const dateTime = appearance.time ? new Date(appearance.time) : new Date(data.event.document.data.start_time_raw)
    return dateTime
  }

  const getFormattedDayOfWeek = (dateTime) => {
    //  Day of week
    const date = dateTime.getHours() < 4 ? dateTime.getDate() - 1 : dateTime.getDate()
    dateTime.setDate(date)
    const dayOfWeek = dateTime.toLocaleString('fr-CA', { weekday: 'long' })
    const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)

    // Day in month
    const dayInMonth = dateTime.getDate()

    // Month
    const month = dateTime.toLocaleString('fr-CA', { month: 'long' })
    const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1)

    // Year
    return formattedDayOfWeek + ' ' + dayInMonth + ' ' + formattedMonth// + " " + formattedYear;
  }

  return (
    <div className={numberOfAppearances > 1 ? 'mx-0 md:w-1/2 w-full mb-2 md:max-w-[50%] max-w-full' : 'w-full mb-2'}>
      <SectionTitle className={'font-ohno font-black text-yellow-wolf2024 tracking-wide text-center text-3xl'}>
        {getFormattedDayOfWeek(getDateTime())}
      </SectionTitle>
      <div className="font-openSans font-bold text-yellow-wolf2024 tracking-wider text-xl mb-1">
        {title
          ? (
          <a href="/infos/carte#info_content" >
            {title.text}
          </a>
            )
          : null}
      </div>
      <div className="font-openSans font-semibold text-white text-md lg:text-lg text-center tracking-tight">
        <ul className="list-none mb-0 p-0">
          {appearances.length > 0 &&
            appearances.map((appearance, i) => {
              return (
                  <li className="mt-0" key={i}>
                    <span className="lowercase">
                      {getHoursWith2023Format(getDateTime(i))}
                      {' • '}
                    </span>
                    <a href={'/artistes/' + appearance.artist.document.slug}
                       className="text-center hover:underline">
                      {appearance.artist.document.data.title.text}
                    </a>
                  </li>
              )
            })}
        </ul>
      </div>
      <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
        {isFree
          ? ''
          : <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} my-12`}>
              <a href={getTicketURL()} className="font-ohno uppercase text-purple-wolf2023 text-2xl px-12 py-4 bg-yellow-wolf2024 rounded-3xl hover:opacity-20 transition-opacity duration-200 ease-in-out">
                {'Billets'}
              </a>
            </div>
        }
        {lpdvID.text && lpdvID.text !== ''
          ? <div>
              <a href={`https://lepointdevente.com/tickets/${lpdvID.text}`} className="font-ohno uppercase text-purple-wolf2023 text-2xl px-12 py-4 bg-yellow-wolf2024 rounded-3xl hover:opacity-20 transition-opacity duration-200 ease-in-out">
                {'Réserver'}
              </a>
            </div>
          : ''
        }
      </div>

    </div>
  )
}

export default Appearances
