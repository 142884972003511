import React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import Appearances from '../components/Appearances/Appearances'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import BigButton from '../components/Ui/BigButton'

const Artist = ({ pageContext, location }) => {
  const {
    title,
    main_image: mainImage,
    from,
    youtube_ids: youtubeIds,
    appearances,
    metadescription,
    metakeywords,
    metatitle,
    og_img: ogImage,
    rue_festive_only: rueFestiveOnly,
    socialmedias_csv: socialMediasCSV
  } = pageContext.post.data

  let activitesDiverses = pageContext.post.data
  activitesDiverses |= false

  const image = getImage(mainImage)
  let free = false

  appearances.data.allPrismicAppearances.edges.forEach(({ node }) => {
    const currentEvent = node.data.event.document?.data
    if (currentEvent?.free) {
      free = true
    }
  })

  const sortedAppearances = appearances.data.allPrismicAppearances.edges
  sortedAppearances.sort(function (a, b) {
    const keyA = new Date(b.node.data.event.document.event_appearances.data.allPrismicAppearances.edges[0].node.data.time)
    const keyB = new Date(a.node.data.event.document.event_appearances.data.allPrismicAppearances.edges[0].node.data.time)

    if (keyA > keyB) return -1
    if (keyA < keyB) return 1
    return 0
  })

  let facebook = pageContext.post.data.facebook.url
  let instagram = pageContext.post.data.instagram.url
  let spotify = pageContext.post.data.spotify.url
  let website = pageContext.post.data.website.url
  let apple = pageContext.post.data.apple_music.url
  let youtube
  let tiktok
  socialMediasCSV.text.split(',').forEach(function (url) {
    if (!facebook && url.includes('facebook')) {
      facebook = url
    } else if (!instagram && url.includes('instagram')) {
      instagram = url
    } else if (!spotify && url.includes('spotify')) {
      spotify = url
    } else if (!youtube && url.includes('youtube')) {
      youtube = url
    } else if (!tiktok && url.includes('tiktok')) {
      tiktok = url
    } else if (!apple && url.includes('apple')) {
      apple = url
    } else if (url.length > 5) {
      website = url
    }
  })

  function compareStartTime (appearance1, appearance2) {
    return new Date(appearance1.node.data.event.document.data.start_time_raw) - new Date(appearance2.node.data.event.document.data.start_time_raw)
  }

  const pageTitle = 'Artistes'
  return (
    <Wolflayout title={pageTitle}>
      <FestifHelmet htmlAttributes={{ lang: 'fr' }}>
        <title>
          {metatitle.text
            ? metatitle.text
            : title.text + ' - Artistes | Le Festif! de Baie-Saint-Paul'}
        </title>
        <meta name="description" content={metadescription.text} />
        <meta name="keywords" content={metakeywords.text} />
        <meta
          property="og:title"
          content={
            metatitle.text
              ? metatitle.text
              : title.text + ' - Artistes | Le Festif! de Baie-Saint-Paul'
          }
        />
        <meta property="og:description" content={metadescription.text} />
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="website" />
        {ogImage ? <meta property="og:image" content={ogImage.url} /> : null}
        <script src="https://lepointdevente.com/plugins/cart/js?locale=fr_CA&amp;ga=UA-32197713-2"></script>
      </FestifHelmet>
      <Banner title={pageTitle} />
      <div className="w-full py-0 px-8 lg:px-16">
        <section className="">
        { free || activitesDiverses || rueFestiveOnly
          ? <p className="font-openSans text-medium text-white text-center my-4 w-full">Ce spectacle fait aussi partie de</p>
          : null }
          <div className="flex justify-center mb-12">

            {/* GRATUIT */}
            <div className={'text-center'}>
              {free
                ? (
                <span className={'text-lg uppercase font-poppins !text-extrabold text-white font-bold py-2 px-4 rounded-2xl inline-block mr-2 bg-blue-wolf'}>
                  Gratuit
                </span>
                  )
                : null}
            </div>

            {/* ACTIVITÉS DIVERSES */}
            <div className={'text-center'}>
              {activitesDiverses || rueFestiveOnly
                ? (
                <span className={'text-lg uppercase font-poppins !text-extrabold text-white font-bold py-2 px-4 rounded-2xl inline-block mr-2 bg-blue-wolf'}>
                  Activités diverses
                </span>
                  )
                : null}
            </div>

          </div>

          {/* LIENS MEDIAS SOCIAUX */}
          <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
            <div className="mb-8 text-center flex flex-wrap justify-center gap-2">
              {facebook && (
                <a href={facebook} target="_blank" className="social-link" rel="noreferrer">
                  <StaticImage
                    src="../images/wolf/socialmedias/facebook.png"
                    alt="Logo Facebook"
                    className="social-image"
                  />
                </a>
              )}
              {instagram && (
                <a href={instagram} target="_blank" className="social-link" rel="noreferrer">
                  <StaticImage
                    src="../images/wolf/socialmedias/instagram.png"
                    alt="Logo Instagram"
                    className="social-image"
                  />
                </a>
              )}
              {website && (
                <a href={website} target="_blank" className="social-link" rel="noreferrer">
                  <StaticImage
                    src="../images/wolf/socialmedias/web.png"
                    alt="Logo générique de site web"
                    className="social-image"
                  />
                </a>
              )}
              {spotify && (
                <a href={spotify} target="_blank" className="social-link" rel="noreferrer">
                  <StaticImage
                    src="../images/wolf/socialmedias/spotify.png"
                    alt="Logo Spotify"
                    className="social-image"
                  />
                </a>
              )}
              {apple && (
                <a href={apple} target="_blank" className="social-link" rel="noreferrer">
                  <StaticImage
                    src="../images/wolf/socialmedias/apple.png"
                    alt="Logo Apple Music"
                    className="social-image"
                  />
                </a>
              )}
              {tiktok && (
                <a href={tiktok} target="_blank" className="social-link" rel="noreferrer">
                  <StaticImage
                    src="../images/wolf/socialmedias/tiktok.png"
                    alt="Logo TikTok"
                    className="social-image"
                  />
                </a>
              )}
              {youtube && (
                <a href={youtube} target="_blank" className="social-link" rel="noreferrer">
                  <StaticImage
                    src="../images/wolf/socialmedias/youtube.png"
                    alt="Logo Youtube"
                    className="social-image"
                  />
                </a>
              )}
            </div>
          </div>

          {/* NOM DE L'ARTISTE */}
          <h2 className={'uppercase font-ohno text-[65px] text-white text-center leading-none tracking-wider'}>
            {title.text ? title.text : ''}
          </h2>
          <br />

          {/* PROVENANCE */}
          <h3 className={'!font-poppins font-bold text-[20px] text-white text-center tracking-[.3em] uppercase leading-none'}>
            {from ? from.text : ''}
          </h3>
          <br />

          {/* PHOTO DE L'ARTISTE */}
          <div className="flex justify-around">
            <div className="lg:w-2/3 mr-1 relative text-center">
              {image
                ? (
                <GatsbyImage
                  className="w-3/5 h-full max-w-[500px]"
                  image={image}
                  alt={image.alt ? image.alt : ''}
                  imgClassName="rounded-3xl"
                />
                  )
                : null}
            </div>
          </div>
        </section>

        {/* BOUTON RUE FESTIVE */}
        <section className="mt-4 mb-1 text-center flex justify-center">
        {rueFestiveOnly
          ? (
            <BigButton text={"VOIR L'HORAIRE DE LA RUE FESTIVE"} href={'/infos/ruefestive#info_content'} target="_self"/>
            )
          : null}
        </section>

        {/* APPEARANCES */}
        <section className="pb-4 pt-0 flex flex-col md:flex-row flex-wrap text-center md:!max-w-[60%] max-w-full mx-auto px-auto">
          {appearances &&
            sortedAppearances.sort(compareStartTime).map(({ node }) => {
              // eslint-disable-next-line react/jsx-key
              return <Appearances data={node.data} numberOfAppearances={appearances.data.allPrismicAppearances.edges.length} isFree={free} />
            })}
        </section>

        {/* YOUTUBE */}
        <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} mt-4 relative md:h-[25rem] h-[15rem] lg:mx-[25%]`}>
          <iframe
            title={title && youtubeIds ? title.text + youtubeIds.text : ''}
            className="absolute top-0 left-0 w-full h-full mb-3rem"
            src={youtubeIds && youtubeIds.text ? 'https://www.youtube.com/embed/' + youtubeIds.text : ''}
            allowFullScreen=""
            referrerPolicy="origin-when-cross-origin"
            height={'100%'}
          ></iframe>
        </div>
      </div>
      <div className='mt-0'>
        <StaticImage className={'max-w-[1920px]'}
                      src={'../images/wolf/affiche-bottom.png'}
                      placeholder="blurred"
                      objectFit="contain"
                      alt="Visuel floral"/>
      </div>
    </Wolflayout>
  )
}

export default Artist
